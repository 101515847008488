
import { C360Icon } from '@c360/ui';

export default {
  name: 'AgencySwitchMenu',
  inheritAttrs: false,
  components: { C360Icon },
  props: [],
  data: (): {} => ({
    open: false,
    selectedAgency: null,
  }),
  computed: {
    currentAgency() {
      return this.$store.state.customer.user.Agency;
    },
    availableAgencies() {
      return this.$store.state.customer.user.AvailableAgencies;
    },
  },
  methods: {
    changeAgency(agencyId) {
      this.open = false;
      this.$store
        .dispatch('ahswitchagency', { agencyId })
        .then(user => {
          if (!user.email) {
            this.tokenLoading = false;
            this.error = user.error || 'ahlogin failed';
            return;
          }
          localStorage.setItem('ah:products', JSON.stringify(user.products));
          // localStorage.setItem('ah:jwtToken', token); // short refresh
          localStorage.setItem('ah:refreshToken', user.refreshToken); // full refresh
          localStorage.removeItem('hasUpdatedHomeCache');
          window.location.reload();
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.error('ahlogin error', error);
        });
    },
  },
};
