
import Vue from 'vue';

import SettingsMenuC360 from './menus/settingsMenuC360.vue';
import ExportMenu from './menus/exportMenuC360.vue';
import AgencySwitchMenu from './menus/agencySwitchMenu.vue';
import AdvertiserToolbarSearch from './advertiser-selection/advertiserToolbarSearchC360.vue';
import { CurrentSection } from '../../../types/layout';
import LayoutEditorOptionsC360 from './layoutEditingOptions/layoutEditorOptionsC360.vue';
import ExitEditDialog from './layoutEditingOptions/exitEditDigalog.vue';
import EditCampaignPermissionsModal from './layoutEditingOptions/editCampaignPermissionsModal.vue';
import utils from '../../../util';
import analytics from '../../../mixins/analytics';
import { C360Icon, C360Header, useLink } from '@c360/ui';
import SummaryDateSelector from '../../components/toolbar/date-selection/summaryDateSelector.vue';
let mutations: () => void;

export default Vue.extend({
  name: 'toolbarC360',
  props: [
    'currentSection',
    'isLoading',
    'share',
    'showExportButton',
    'recentAdvertisers',
    'dashboardAlertActive',
    'advertiserView',
    'advertisersOnly',
  ],
  mixins: [analytics],
  components: {
    AdvertiserToolbarSearch,
    ExportMenu,
    LayoutEditorOptionsC360,
    ExitEditDialog,
    C360Header,
    SettingsMenuC360,
    C360Icon,
    AgencySwitchMenu,
    SummaryDateSelector,
    EditCampaignPermissionsModal,
  },
  data: (): {
    drawer: boolean;
    advertiserViewSelectedSearch: string;
    advertiserViewSearchOptions: string[];
    showExitEditDialog: boolean;
    daterangeOptions: object;
    selectedDaterange: string;
    orderDaterange: boolean;
    showEditCampaignPermissionsModal: boolean;
    combinedOptions: Array<object>;
    defaultSelectedOption: object;
  } => ({
    drawer: false,
    advertiserViewSelectedSearch: 'Advertiser',
    advertiserViewSearchOptions: ['Advertiser', 'Product', 'Campaign'],
    showExitEditDialog: false,
    daterangeOptions: [
      {
        name: 'Last 7 Days',
        key: 'thisweek',
      },
      {
        name: 'Last 30 Days',
        key: 'thismonth',
      },
      {
        name: 'Last 365 Days',
        key: 'thisyear',
      },
      {
        name: 'All Time',
        key: 'alltime',
      },
    ],
    selectedDaterange: 'Last 30 Days',
    orderDaterange: false,
    showEditCampaignPermissionsModal: false,
    defaultSelectedOption: null,
    combinedOptions: [],
  }),
  mounted() {
    if (this.isSingleOrderPage) {
      this.selectedDaterange = 'Last 30 Days';
    } else {
      this.selectedDaterange = 'Last 365 Days';
    }
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showAlert(): boolean {
      return this.dashboardAlertActive;
    },
    selectedAdvertiserName(): string {
      if (this.$store?.state?.advertiser?.advertiserInfo?.data) {
        const { data } = this.$store.state.advertiser.advertiserInfo;
        if (data.name) {
          return data.name;
        } else if (this.$store?.state?.customer?.sharedSelection?.advertiserName) {
          return this.$store?.state?.customer?.sharedSelection?.advertiserName;
        }
      } else if (this.$store?.state?.customer?.sharedSelection?.advertiserName) {
        return this.$store?.state?.customer?.sharedSelection?.advertiserName;
      }
      return '';
    },
    showDateRangeDropdown(): boolean {
      if (this.currentSection && this.currentSection.id === 'summary') {
        return true;
      }
      return false;
    },
    showShareTitle(): boolean {
      return this.share && this.currentSection?.title;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    isHideHelpCenter(): boolean {
      return this.$store.getters.user.permissions?.includes('HIDE_HELP_CENTER');
    },
    hasCompulseOMS(): boolean {
      return this.$store.getters.user.products?.includes('SSWebApp');
    },
    ignoreProducts(): string[] {
      const ignoreProducts: string[] = [];
      if (this.isHideHelpCenter) {
        ignoreProducts.push('help center');
      }
      if (!this.hasCompulseOMS) {
        ignoreProducts.push('compulse oms');
      }
      return ignoreProducts;
    },
    toolbarOffset(): number {
      if (this.dashboardAlertActive && utils.getEnvForProductLink(true) !== 'prod') {
        return 80;
      }
      if (this.dashboardAlertActive || utils.getEnvForProductLink(true) !== 'prod') {
        return 40;
      }
      return 0;
    },
    showButton(): boolean {
      return (
        !this.isLoading &&
        this.currentSection &&
        (this.currentSection.pdfExport || this.currentSection.pptExport || this.currentSection.xlsExport) &&
        this.showExportButton &&
        !this.$store.getters.networkActivity &&
        !this.inEditMode &&
        !this.isOrderPages &&
        !this.isLanding
      );
    },
    isSingleOrderPage(): boolean {
      return ['ordersummary'].includes(this.$route?.query?.tab);
    },
    isOrderPages(): boolean {
      return ['orderlist', 'ordersummary'].includes(this.$route?.query?.tab);
    },
    isLanding(): boolean {
      return this.$route.query.tab === 'home';
    },
    ahAdvertiserId(): string {
      return this.$store.state.advertiser?.advertiserInfo?.data.ah_advertiser_id;
    },
    dashboardId(): number {
      return this.$store.state.customer?.campaignAdPerformance?.CampaignList[0]?.DashboardId;
    },
  },
  methods: {
    selectDaterange(range): void {
      const selectedRange = this.daterangeOptions.find(r => r.key === range);
      if (this.selectedDaterange === selectedRange.name) return;
      this.selectedDaterange = selectedRange.name;

      if (this.isSingleOrderPage) {
        this.analyticTrack(
          this.trackValue.ORDERS_DETAILS_CHANGE_DATERANGE,
          `Change Date Range: ${this.selectedDaterange}`,
        );

        this.$store.dispatch('order/setSingleOrderParams', {
          daterange: range,
          orderId: this.$route.query.id,
        });
      } else {
        this.analyticTrack(this.trackValue.ORDERS_CHANGE_DATERANGE, `Change Date Range: ${this.selectedDaterange}`);

        const orderParams = this.$store.state.order.orderListParams;
        this.$store.dispatch('order/setOrderlistParams', {
          ...orderParams,
          daterange: range,
        });
      }
    },
    onLogoClick(): void {
      if (this.share) return;
      this.goHome();
    },
    async onPreRedirectCallback(item): Promise<any> {
      switch (item.toLowerCase()) {
        case 'media planner':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_MP);
          break;
        case 'instant io':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_IO);
          break;
        case 'help center':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_HC);
          break;
        case 'portal':
          this.analyticTrack(this.trackValue.EVENT_RETURN_TO);
          break;
      }
      return new Promise(function (resolve) {
        // small timeout to receive answer from mixpanel
        setTimeout(() => {
          resolve('');
        }, 500);
      });
    },
    getLinkByProductName(name: string): any {
      const agency = utils.getAgencyFromURL();
      let tokenQueryParam = '';
      let product = '';
      let token = '';
      const ahtokenmp = localStorage.getItem('ah:jwtToken');
      if (ahtokenmp) {
        tokenQueryParam = `/ahlogin/?token=${ahtokenmp}`;
      }
      switch (name.toLowerCase()) {
        case 'media planner': {
          product = 'mediaplanner';
          return (
            useLink({
              product,
              agency,
              token,
              env: utils.getEnvForProductLink(),
            }) + tokenQueryParam
          );
        }
        case 'dashboard': {
          product = 'analytics';
          break;
        }
        case 'help center': {
          const agency = utils.getAgencyFromURL();
          const baseURL = useLink({
            product: 'compulse360',
            agency,
            env: utils.getEnvForProductLink(),
          });
          return `${baseURL}/help`;
        }
        case 'instant io': {
          product = 'mediaplanner';
          token = ahtokenmp;
          const base = useLink({
            product,
            agency,
            env: utils.getEnvForProductLink(),
          });
          return `${base}/ahlogin?token=${token}&redirectUri=${base}?modal=insertionOrders`;
        }
        // case 'compulse oms': {
        //   const refreshToken = localStorage.getItem('ah:refreshToken');
        //   return () => {
        //     redirectToSSWebApp(refreshToken);
        //   }
        // }
      }
      return (
        useLink({
          product,
          agency,
          token,
          env: utils.getEnvForProductLink(),
        }) + tokenQueryParam
      );
    },
    updateAdvertiserView(params: { [key: string]: string }): void {
      this.$emit('updateAdvertiserView', params);
    },
    getTacticName(name: string): string {
      return utils.getTacticName(name);
    },
    selectAdvertiser(searchValue: string): void {
      this.$emit('select-advertiser', searchValue);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    handleDrawerToggle(): void {
      this.$store.dispatch('setDrawerOpen', !this.$store.state.customer.drawerOpen);
    },
    goHome(): void {
      if (this.inEditMode) {
        // show save dialog if unsaved work and trying to exit to home
        if (this.$store.state.layoutEditor.enableSave) {
          this.showExitEditDialog = true;
        } else {
          this.$store.dispatch('layoutEditor/setCurrCustomizations', null);
          this.$store.dispatch('layoutEditor/toggleEditMode', false);
          this.$store.dispatch('layoutEditor/closeEditComponent');
          this.showExitEditDialog = false;
          this.$router.push({ path: '/home?recent=true&tab=home' });
        }
      } else if (!(this.$route.name.includes('home') && this.$route.query?.recent)) {
        this.$router.push({ path: '/home?recent=true&tab=home' });
      }
    },
    exitDesignMode(): void {
      if (this.enableSave) {
        this.showExitEditDialog = true;
      } else {
        this.$store.dispatch('layoutEditor/setCurrCustomizations', null);
        this.$store.dispatch('layoutEditor/toggleEditMode', false);
        this.$store.dispatch('layoutEditor/closeEditComponent');
        this.showExitEditDialog = false;
      }
    },
    advertiserReturnButton(): void {
      const recentStr: string | null = localStorage.getItem(`${this.$store.getters.user.email}:recentPages`);
      if (!recentStr) {
        this.$router.push({ name: 'home', query: { recent: 'true' } });
      } else {
        const lastCampaign = JSON.parse(recentStr).find(page => (page.name = 'home' && page.query?.id));
        this.$router.push({ name: 'home', query: lastCampaign ? lastCampaign.query : { recent: 'true' } });
      }
    },
    saveRoute(): void {
      const key = `${this.$store.getters.user.email}:recentPages`;
      const to = this.$route;
      if (to.query?.id && to.query?.tab && to.meta.returnable) {
        // only save the tactic route if we have campaigns.
        if (
          to.query.tab === 'summary' ||
          to.query.tab === 'orderlist' ||
          to.query?.viewCampaigns ||
          to.query?.summaryView
        )
          utils.saveRoutes(to, `${this.selectedAdvertiserName} - ${this.currentSection.title}`, key);
      }
    },
    hideDialog(): void {
      this.showExitEditDialog = false;
    },
    checkAgencyPermission(agencyName): boolean {
      return this.$store.getters.user.Agency === agencyName;
    },
    beforeDestroy() {
      mutations();
    },
    mounted() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutations = this.$store.subscribe((mutation: any) => {
        switch (mutation.type) {
          case 'SET_ALL_TIME_AD_PERFORMANCE':
            this.$forceUpdate();
            break;
          default:
            break;
        }
      });
    },
    closeEditCampaignPermissionsModal(): void {
      this.showEditCampaignPermissionsModal = false;
      this.defaultSelectedOption = null;
    },
    async openEditCampaignPermissionsModal(): Promise<void> {
      const advertiserId = this.$route.query.id;
      const details = await this.$store.dispatch('advertiser/getAdvertiserInfo', {
        id: advertiserId,
        includeStations: true,
        includeSubagencies: true,
      });

      const subAgenciesOptions = details.data.ahSubagencies.map(subagency => ({
        name: `${subagency.name} (Subagency)`,
        id: subagency.id,
        type: 'subagency',
      }));

      const stationsOptions = details.data.ahStations.map(station => ({
        name: `${station.name} (Station)`,
        id: station.id,
        type: 'station',
      }));

      this.combinedOptions = [...subAgenciesOptions, ...stationsOptions];

      this.fetchDefaultPermission();

      this.showEditCampaignPermissionsModal = true;
    },
    async handleSavePermissions({ selectedOption, selectedType }): Promise<void> {
      let deleteSuccessful = true;

      if (!this.defaultSelectedOption) {
        await this.updateCampaign(selectedOption, selectedType);
        this.closeEditCampaignPermissionsModal();
      } else {
        if (this.defaultSelectedOption.type === 'subagency') {
          deleteSuccessful = await this.deleteCampaign('subagency', this.defaultSelectedOption.id);
        }
        if (this.defaultSelectedOption.type === 'station') {
          deleteSuccessful = await this.deleteCampaign('station', this.defaultSelectedOption.id);
        }

        if (deleteSuccessful) {
          await this.updateCampaign(selectedOption, selectedType);
          this.closeEditCampaignPermissionsModal();
        }
      }
    },
    async updateCampaign(selectedOption: string, selectedType: string): Promise<void> {
      if (selectedType === 'subagency') {
        await this.$store
          .dispatch('updateAhAdvertiserSubagencyCampaign', {
            advertiserId: this.ahAdvertiserId,
            subagencyId: selectedOption,
            campaignId: this.dashboardId,
          })
          .catch(() => {
            this.$store.dispatch('showError', {
              message: 'Error occured, please try again',
              errorType: 'error',
            });
          });
      } else if (selectedType === 'station') {
        await this.$store
          .dispatch('updateAhAdvertiserStationCampaign', {
            advertiserId: this.ahAdvertiserId,
            stationId: selectedOption,
            campaignId: this.dashboardId,
          })
          .catch(() => {
            this.$store.dispatch('showError', {
              message: 'Error occured, please try again',
              errorType: 'error',
            });
          });
      }
    },
    async deleteCampaign(type: string, id: string): Promise<boolean> {
      try {
        if (type === 'subagency') {
          await this.$store.dispatch('deleteAhAdvertiserSubagencyCampaign', {
            advertiserId: this.ahAdvertiserId,
            subagencyId: id,
            campaignId: this.dashboardId,
          });
        } else if (type === 'station') {
          await this.$store.dispatch('deleteAhAdvertiserStationCampaign', {
            advertiserId: this.ahAdvertiserId,
            stationId: id,
            campaignId: this.dashboardId,
          });
        }
        return true;
      } catch (error) {
        this.$store.dispatch('showError', {
          message: 'Error occurred, please try again',
          errorType: 'error',
        });
        return false;
      }
    },
    async fetchDefaultPermission(): Promise<void> {
      try {
        const defaultPermission = await this.$store.dispatch('getAhAdvertiserCampaign', {
          advertiserId: this.ahAdvertiserId,
          campaignId: this.dashboardId,
        });
        if (defaultPermission.subagency) {
          this.defaultSelectedOption = {
            name: `${defaultPermission.subagency.name} (Subagency)`,
            id: defaultPermission.subagency.ahId,
            type: 'subagency',
          };
        } else if (defaultPermission.station) {
          this.defaultSelectedOption = {
            name: `${defaultPermission.station.name} (Station)`,
            id: defaultPermission.station.ahId,
            type: 'station',
          };
        }
      } catch (error) {
        console.error('Failed to fetch default permissions:', error);
      }
    },
  },
  watch: {
    currentSection() {
      Vue.nextTick(() => {
        try {
          if (document && this.currentSection && this.selectedAdvertiserName) {
            // const data = this.$store.state.customer.adPerformance as AdPerformance; // ${data.StartDate}:${data.EndDate} -
            document.title = `${this.currentSection.title} - ${this.selectedAdvertiserName} -  Analytics Dashboard`;
            // Save route history
            this.saveRoute();
          }
        } catch (exp) {
          // eslint-disable-next-line no-console
          console.error(exp);
        }
      });
    },
    '$route.query.tab': {
      handler(): void {
        if (this.$route?.query?.tab === 'ordersummary') {
          this.selectedDaterange = 'Last 30 Days';
        } else {
          this.selectedDaterange = 'Last 365 Days';
        }
      },
    },
    '$route.query.id': {
      handler(): void {
        if (!this.isOrderPages) return;
        if (this.$route?.query?.tab === 'ordersummary') {
          this.selectedDaterange = 'Last 30 Days';
        } else {
          this.selectedDaterange = 'Last 365 Days';
        }
      },
    },
    '$route.query.viewCampaigns': {
      async handler(): Promise<void> {
        Vue.nextTick(() => {
          try {
            if (this.currentSection && this.selectedAdvertiserName) {
              // Save route history
              this.saveRoute();
            }
          } catch (exp) {
            // eslint-disable-next-line no-console
            console.error(exp);
          }
        });
      },
      immediate: true,
    },
    advertiserSearch(val: string) {
      if (!val) {
        // wipes out the searchvalue object
        // this prevents a bug where you get 0 results when searching for the same string that's in the selected advertiser name
        this.searchValue = null;
        return;
      }
      if (val.length < 3) {
        this.search('');
        return;
      }
      this.search(val);
    },
  },
});
